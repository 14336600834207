/**
 * Created by mac on 2019-03-28
 */

cleverapps.styles.horizontalScrollDisabled = true;
 
cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    GOAL_TEXT: {
        name: "default"
    },
    CLOVERS_TEXT: {
        name: "default"
    }
});

cleverapps.overrideStyles(cleverapps.styles.GameScene, {
    actor: {
        size: {
            width: 200,
            height: 200
        },

        wideModePositions: [
            {
                x: { align: "right", dx: -50 },
                y: { align: "bottom", dy: -200 }
            },
            {
                x: { align: "right", dx: -50 },
                y: { align: "bottom", dy: -180 }
            },
            {
                x: { align: "right", dx: -50 },
                y: { align: "bottom", dy: -180 }
            }
        ],

        spine: bundles.game.jsons.actor_json
    }
});

cleverapps.overrideStyles(cleverapps.styles.ControlsPanel, {
    position: [
        {
            x: { align: "center", dx: 0 },
            y: { align: "bottom", dy: 0 }
        },
        {
            x: { align: "right", dx: 0 },
            y: { align: "center", dy: 0 }
        },
        {
            x: { align: "right", dx: 0 },
            y: { align: "center", dy: 0 }
        }
    ],

    sizes: [
        {
            width: 650,
            height: 180
        },
        {
            width: 180,
            height: 650
        },
        {
            width: 180,
            height: 1000
        }
    ]
});

cleverapps.overrideStyles(cleverapps.styles.InfoPanel, {
    margins: undefined
});

if (typeof ColorComponent !== "undefined") {
    cleverapps.styles.COLORS.CELL_COLORS = {};
    cleverapps.styles.COLORS.CELL_COLORS[ColorComponent.COLOR_A] = new cc.Color(20, 160, 237, 255);
    cleverapps.styles.COLORS.CELL_COLORS[ColorComponent.COLOR_B] = new cc.Color(20, 200, 0, 255);
    cleverapps.styles.COLORS.CELL_COLORS[ColorComponent.COLOR_C] = new cc.Color(195, 61, 225, 255);
    cleverapps.styles.COLORS.CELL_COLORS[ColorComponent.COLOR_D] = new cc.Color(255, 11, 28, 255);
    cleverapps.styles.COLORS.CELL_COLORS[ColorComponent.COLOR_E] = new cc.Color(234, 199, 48, 255);
    cleverapps.styles.COLORS.CELL_COLORS[ColorComponent.COLOR_W] = new cc.Color(50, 200, 255, 255);
}

cleverapps.overrideStyles(cleverapps.styles.MuffinComponentView, {
    boomColor: new cc.Color(156, 51, 0, 255)
});

cleverapps.overrideStyles(cleverapps.styles.BaseGoalView, {

    width: 100,
    height: 120,
    scaleX: -0.85,
    scaleY: 0.85,

    icon: {
        x: 0,
        y: 0
    },

    amount: {
        x: 40,
        y: 30
    }

});

cleverapps.overrideStyles(cleverapps.styles.GoalsView, {
    positionNoMission: [{
        x: { align: "center", dx: 77 },
        y: { align: "center", dy: 15 }
    }, {
        x: { align: "center", dx: 77 },
        y: { align: "center", dy: 15 }
    }, {
        x: { align: "center", dx: -20 },
        y: { align: "center", dy: -85 },
        rotation: -90
    }],

    positionWithMission: [{
        x: { align: "center", dx: 10 },
        y: { align: "center", dy: 15 }
    }, {
        x: { align: "center", dx: 10 },
        y: { align: "center", dy: 15 }
    }, {
        x: { align: "center", dx: -17 },
        y: { align: "center", dy: -25 },
        rotation: -90
    }]
});

cleverapps.overrideStyles(cleverapps.styles.AvailableMoveView, {
    animation: AVAILABLE_MOVE_ANIMATIONS.default,
    animateShape: false
});

cleverapps.overrideStyles(cleverapps.styles.LocationView, {
    height: cleverapps.styles.SCENE_HEIGHT / 2.5,
    finger: {
        delay: 15
    }
});