/**
 * Created by anatoly on 09.09.2024
 */

var BoerAnimation = cc.Node.extend({
    ctor: function (animationView, component) {
        this._super();
        this.component = component;
        this.animationView = animationView;

        this.wideMode = cleverapps.resolution.mode;

        this.addScale9Wall();
        this.addBrickWall();
        this.addBoers();

        var wallBg = this.wallBg = cleverapps.UI.createScale9Sprite(bundles.danger_component.frames.sand_wall_bg);
        this.addChild(wallBg);

        this.onAnimationResize();
    },

    addScale9Wall: function () {
        if (this.wall) {
            this.wall.removeFromParent();
        }

        var bgName = cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL ? "sand_bg" : "sand_bg_vertical";
        var wall = this.wall = cleverapps.UI.createScale9Sprite(bundles.danger_component.frames[bgName]);
        wall.setAnchorPoint(1, 0.5);

        this.addChild(wall);
    },

    startAnimation: function () {
        this.runAction(new cc.RepeatForever(
            new cc.Sequence(
                new cc.CallFunc(function () {
                    this.boerSound = cleverapps.audio.playSound(bundles.danger_component.urls.tricky_boer);
                }.bind(this)),
                new cc.DelayTime(8)
            )
        ));

        var timeLeft = this.component.getTimeLeft();

        this.wall.runAction(new cc.ScaleTo(timeLeft / 1000, 1));
        this.blocksWallNode.runAction(new cc.MoveTo(timeLeft / 1000, -this.getEndPosition(), this.blocksWallNode.y));
    },

    onAnimationResize: function () {
        this.stopAnimation();

        var percentOfCompletion = this.component.getPercentOfCompletion();
        var endPosition = this.getEndPosition();

        if (this.needRecreate()) {
            this.wideMode = cleverapps.resolution.mode;

            this.addScale9Wall();
            this.addBrickWall();
            this.addBoers();
        }

        this.wall.setContentSize2(endPosition, this.animationView.height);
        this.wall.setScale(percentOfCompletion, 1);

        this.blocksWallNode.setPosition(-endPosition * percentOfCompletion, this.getBlocksWallY());

        var wallBg = this.wallBg;
        if (this.wideMode === cleverapps.WideMode.HORIZONTAL) {
            wallBg.setContentSize(wallBg.width, this.animationView.height);
            wallBg.rotation = 0;
            wallBg.setPosition(wallBg.width / 2, 0);

            wallBg.setLocalZOrder(0);
        } else {
            wallBg.setContentSize(wallBg.width, this.animationView.width);
            wallBg.rotation = 90;
            wallBg.setPosition(-this.animationView.width / 2, -this.animationView.height / 2 - wallBg.width / 2);

            wallBg.setLocalZOrder(2);
        }

        if (this.component.started) {
            this.startAnimation();
        }
    },

    getEndPosition: function () {
        var hero = this.animationView.hero;

        return this.animationView.width - hero.getPosition().x - (hero.width / 2 * hero.scale);
    },

    needRecreate: function () {
        return this.blocksWallNode.height * this.blocksWallNode.scale < this.animationView.height
            || this.wideMode !== cleverapps.resolution.mode;
    },

    addBrickWall: function () {
        if (this.blocksWallNode) {
            this.blocksWallNode.removeFromParent();
        }

        var styles = cleverapps.styles.DangerAnimation.scales;
        var scale = styles[cleverapps.resolution.mode];

        var blocks = [];

        var bundle = bundles.danger_component.frames.block;

        var blockHeight = bundle.getSpriteFrame().getOriginalSize().height * scale;

        for (var height = 0; height < this.animationView.height; height += blockHeight) {
            blocks.push(new cc.Sprite(bundle));
        }

        var blocksWallNode = this.blocksWallNode = new cleverapps.Layout(blocks);

        blocksWallNode.setScale(scale);

        this.addChild(blocksWallNode, 1);
    },

    getBlocksWallY: function () {
        return (this.blocksWallNode.height * this.blocksWallNode.scale - this.animationView.height) / 2;
    },

    addBoers: function () {
        var styles = cleverapps.styles.BoerAnimation.boers;
        var boersCount = 3;

        var boers = this.boers = [];

        for (var i = 0; i < boersCount; i++) {
            var boer = new cleverapps.Spine(bundles.danger_component.jsons.boer);
            boer.setAnimation(0, "idle", true);

            boers.push(boer);
        }

        boers = new cleverapps.Layout(boers, {
            margin: styles.margin[cleverapps.resolution.mode]
        });

        styles.y.dy = -this.getBlocksWallY() / this.blocksWallNode.scale;

        boers.setPositionRound(styles);
        this.blocksWallNode.addChild(boers);
    },

    setTimeScale: function (timeScale) {
        this.boers.forEach(function (boer) {
            boer.setTimeScale(timeScale);
        });
    },

    stopAnimation: function () {
        this.wall.stopAllActions();
        this.blocksWallNode.stopAllActions();

        this.boers.forEach(function (boer) {
            boer.setAnimation(0, "stop");
        });
    },

    stop: function () {
        this.stopAnimation();
        this.stopAllActions();

        cleverapps.audio.stopSound(this.boerSound);

        this.runAction(new cc.RepeatForever(
            new cc.Sequence(
                new cc.PlaySound(bundles.danger_component.urls.tricky_boer_win),
                new cc.DelayTime(3)
            )
        ));
    }
});

cleverapps.styles.BoerAnimation = {
    x: { align: "right" },
    y: { align: "center" },

    boers: {
        x: { align: "center", dx: -170 },
        y: { align: "center" },

        margin: [20, 20, 70]
    }
};